import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
                           children,
                            ...props
                       }) => {
    var iframe = document.getElementById(props.title);

    if (iframe) {
        const iframeWin = iframe.contentWindow || iframe;
        iframeWin.console.log = function() { /* nop */ }; //silences all console logs from iframe
        iframeWin.onerror= function(error) {
            iframeWin.location.reload();
            return true;
        }
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var script = iframeDoc.createElement('script');

        script.append(`
    Enabler.setProfileId(10920678);
    var devDynamicContent = {};

    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_OHR_Logos_and_Colors = [{}];
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_OHR_Logos_and_Colors[0]._id = 0;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_OHR_Logos_and_Colors[0].Color_Scheme = "Cream Headline\/Cream Font";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_OHR_Logos_and_Colors[0].Headline_Color = "${props.Headline_Color}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_OHR_Logos_and_Colors[0].CTA_Box_Color = "${props.CTA_Box_Color}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_OHR_Logos_and_Colors[0].CTA_Font_Color = "${props.CTA_Font_Color}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_OHR_Logos_and_Colors[0].Logo_URL = "${props.Logo_URL.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni__New__Prospecting = [{}];
    devDynamicContent.Omni__New__Prospecting[0]._id = 0;
    devDynamicContent.Omni__New__Prospecting[0].Unique_ID = 1;
    devDynamicContent.Omni__New__Prospecting[0].Reporting_Label = "Test";
    devDynamicContent.Omni__New__Prospecting[0].IsApproved = true;
    devDynamicContent.Omni__New__Prospecting[0].IsDefault = false;
    devDynamicContent.Omni__New__Prospecting[0].HotelCodes = "";
    devDynamicContent.Omni__New__Prospecting[0].Campaign_Budget_Source = "Corporate";
    devDynamicContent.Omni__New__Prospecting[0].StartDate = {};
    devDynamicContent.Omni__New__Prospecting[0].StartDate.RawValue = "05/09/2023";
    devDynamicContent.Omni__New__Prospecting[0].StartDate.UtcValue = 1683615600000;
    devDynamicContent.Omni__New__Prospecting[0].EndDate = {};
    devDynamicContent.Omni__New__Prospecting[0].EndDate.RawValue = "11/27/2024";
    devDynamicContent.Omni__New__Prospecting[0].EndDate.UtcValue = 1732694400000;
    devDynamicContent.Omni__New__Prospecting[0].Exit_Url = "${props.Exit_Url}";
    devDynamicContent.Omni__New__Prospecting[0].Headline = "${props.Headline}";
    devDynamicContent.Omni__New__Prospecting[0].SubHeadline = "${props.SubHeadline}";
    devDynamicContent.Omni__New__Prospecting[0].CTA = "${props.CTA}";
    devDynamicContent.Omni__New__Prospecting[0].Image_Vertical = "${props.Image_Vertical.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni__New__Prospecting[0].Image_Vertical_Preview = "";
    devDynamicContent.Omni__New__Prospecting[0].Image_Horizontal = "${props.Image_Horizontal.replace(/\s+$/gm, '').replace(/[\r\n]/g, '')}";
    devDynamicContent.Omni__New__Prospecting[0].Image_Horizontal_Preview = "";
    devDynamicContent.Omni__New__Prospecting[0].Reporting_Label_2 = "test";
    devDynamicContent.Omni__New__Prospecting[0].Color_Theme = "example";
    Enabler.setDevDynamicContent(devDynamicContent);`)

        document.getElementById(props.title).contentWindow.addEventListener("load", function (e) {
            //document.getElementsByTagName('iframe')[4].contentWindow.document.body.appendChild(script);
            Array.from(document.getElementsByTagName('iframe')[props.iframenumber].contentWindow.document.head.querySelectorAll('script')).pop().append(script);
        })
    }
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} title={props.title} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}
